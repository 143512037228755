body {
    font-family: 'Open Sans Condensed';
    padding: 20px 60px;
}

a {
    text-decoration: none;
    color: black;
}

* {
    box-sizing: border-box;
}